<template>
    <v-card>
        <v-card-title class="text-h6">
            {{ $t(`labels.history`) }}
            <v-spacer></v-spacer>
            <v-btn small color="error" outlined @click="cancel">
                {{ $t('labels.close') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-simple-table fixed-header height="calc(100vh - 245px)" class="table-padding-2">
                <template v-slot:default>
                    <thead class="v-data-table-header">
                        <tr>
                            <th role="columnheader">
                                <DateRangeFilter :label="$t('labels.create_time')" :placeholder="$t('labels.create_time')"
                                    sort-name="created_at" name="created_at" has-sort @onFilter="onFilterChange"
                                    @onSort="onSortChange" />
                            </th>
                            <th role="columnheader">
                                <SelectEmployee :label="$t('labels.employee_create')" :placeholder="$t('labels.employee_create')"
                                    name="id_employee_create" sort-name="employee_create_name" has-sort
                                    :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
                            </th>
                            <th role="columnheader">
                                <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')"
                                    name="id_pos" sort-name="pos_code" has-sort :sorting="filters.sort_by"
                                    @onFilter="onFilterChange" @onSort="onSortChange" />
                            </th>
                            <th role="columnheader">
                                <InputFilter :label="$t('labels.packaging_code')" :placeholder="$t('labels.packaging_code')"
                                    name="packaging_code" sort-name="packaging_code" has-sort :sorting="filters.sort_by"
                                    @onFilter="onFilterChange" @onSort="onSortChange" />
                            </th>
                            <th role="columnheader">
                                <InputFilterFromTo :label="$t('labels.quantity')" :placeholder="$t('labels.quantity')"
                                    name="quantity" sort-name="quantity" has-sort :sorting="filters.sort_by"
                                    @onFilter="onFilterChange" @onSort="onSortChange" />
                            </th>
                            <th role="columnheader">
                                <InputFilter :label="$t('labels.import')" :placeholder="$t('labels.import')"
                                    name="supplier_name" sort-name="supplier_name" has-sort :sorting="filters.sort_by"
                                    @onFilter="onFilterChange" @onSort="onSortChange" />
                            </th>
                            <th role="columnheader" class="text-center">
                                <SelectEmployee :label="$t('labels.export')" :placeholder="$t('labels.export')"
                                    name="id_employee_receipt" sort-name="employee_receipt_name" has-sort
                                    :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in items" :key="`p_${key}_${item.id}`" class="text-center">
                            <td>{{ formatDateTime(item.created_at) }}</td>
                            <td>{{ item.employee_create_name }}</td>
                            <td>{{ item.pos_code }}</td>
                            <td>{{ item.packaging_code }}</td>
                            <td>{{ formatNumber(item.quantity) }}</td>
                            <td>{{ item.supplier_name }}</td>
                            <td>{{ item.employee_receipt_name }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-pagination v-model="page" :length="totalPage" :total-visible="5"></v-pagination>
        </v-card-text>
    </v-card>
</template>


<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
    name: "HistoryDialog",
    components: {
        SelectPos: () => import('@/components/table/SelectPos'),
        SelectEmployee: () => import('@/components/table/SelectEmployee'),
        DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
        InputFilter: () => import('@/components/table/InputFilter'),
        InputFilterFromTo: () => import('@/components/table/InputFilterFromTo'),
    },
    props: {

    },
    data: () => ({
        page: 1,
        totalPage: 1,
        items: [],
        filters: {},
    }),
    computed: {

    },
    watch: {
        page() {
            this.getList()
        },
        filters: {
            handler() {
                this.page = 1
                this.getList()
            },
            deep: true
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        cancel() {
            this.$emit('cancel')
        },
        onFilterChange(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
        },
        onSortChange(sort) {
            this.filters = { ...this.filters, ...sort }
        },
        getList: debounce(function () {
            httpClient.post('/packaging-quantity-history', { ...this.filters, page: this.page }).then(({ data }) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
            })
        }, 1000),
    }
}
</script>